body {
  background-color: #dbdfe4;
}

.header-bg {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0px;
  z-index: 100;
}

.account-link {
  text-decoration: none !important;
  color: white !important;
}

.ant-table-body {
  overflow-x: auto !important;
}

.tabella tr:nth-child(2n) td {
  background-color: rgb(240, 240, 240);
}

.tabella-infinite tr td {
  overflow-x: hidden;
}

.section {
  border-radius: 4px;
  background-color: white;
}

.headingBtns {
  line-height: 3;
}

.trash-btn {
  border: none;
  background: transparent;
  font-size: 20px;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

#agreementId {
  text-align: left !important;
  padding-right: 0% !important;
}

.spinner {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 10000;
}

.spinnerContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99999;
}

#supplier_list {
  display: flex !important;
  justify-content: center !important;
}

.dropdownTextVoice:hover {
  cursor: row !important;
  color: #212529;
  background-color: white !important;
}

.dropdownTextVoice:active {
  cursor: row !important;
  pointer-events: none;
  color: #212529;
  background-color: white !important;
}

/* FACE BUTTONS */

td .smileFace {
  border-color: green !important;
  color: green !important;
}

.smileFace:hover {
  border-color: green !important;
  color: green !important;
}

td .mehFace {
  border-color: rgb(252, 212, 73) !important;
  color: rgb(252, 212, 73) !important;
}

.mehFace:hover {
  border-color: rgb(252, 212, 73) !important;
  color: rgb(252, 212, 73) !important;
}

td .frownFace {
  border-color: red !important;
  color: red !important;
}

.frownFace:hover {
  border-color: red !important;
  color: red !important;
}

.ant-tag-checkable {
  background-color: rgb(250, 250, 250);
  border-color: #d9d9d9;
}

.ant-tag-checkable-checked {
  background-color: #1890ff !important;
}

.filtersBtn {
  color: #40a9ff;
  background: #fff;
  border-color: #40a9ff;
}

.flagIcons {
  margin-bottom: 0.07rem;
  zoom: 1.2;
}

.flex-1 {
  flex: 1;
}

.absolute-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.radio-tags:before {
  display: none !important;
}

.radio-tags:after {
  display: none !important;
}

/* DROPDOWN SUBMENU */

.dropbtn {
  background-color: transparent;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */

.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */

.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */

.dropdown-submenu:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */

.dropdown-submenu:hover .dropbtn {
  background-color: #e9ecef;
}

.ir-hide-html-elements {
  text-overflow: ellipsis;
  max-height: 150px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.ir-hide-html-elements table,
.ir-hide-html-elements img {
  display: none;
}

.ir-modal-center-content,
.ir-modal-center-content table,
.ir-modal-center-content img {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

svg {
  vertical-align: baseline !important;
}

.fsiList .ant-list-items {
  margin-top: 10px
}

.fsiList .ant-list-item {
  padding: 10px;
  cursor: pointer;
  transition: .2s ease-in;
}

.fsiList .ant-list-item:hover span {
  text-decoration: underline;
}

.fsiList .ant-list-item:hover {
  background-color: #fafafa;
}

.fsiList .ant-list-item-meta-avatar {
  line-height: 4;
}

.fsiList ul.ant-list-items {
  max-height: 50vh;
  overflow-y: auto;
}

.fsiList ul.ant-list-items::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 10px;
  background-color: #F5F5F5;
}

.fsiList ul.ant-list-items::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.fsiList ul.ant-list-items::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #7a7a7a;
}

.fsiList .ant-list-header {
  border-bottom-color: #d2d2d2;
}

.vh-80 {
  min-height: 80vh;
}

.spinnerCont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh !important;
}

.localSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 75;
  background-color: rgba(255, 255, 255, .5);
  width: 98%;
  height: 96% !important;
  top: -1px
}

.ant-breadcrumb-link {
  outline: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}

.ant-breadcrumb-link:hover {
  text-decoration: underline;
}

.ant-breadcrumb span:last-child,
.ant-breadcrumb span:last-child>.ant-breadcrumb-link:hover {
  font-weight: bold !important;
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
}

.selectableTableRow:hover {
  cursor: pointer;
  text-decoration: underline;
}

.deletedFile {
  color: #797979;
  text-decoration: line-through;
}

.breadcrumbs {
  line-height: 2.5;
  border: 1px solid #d5d5d5;
  display: flex;
  overflow-x: auto;
  padding-left: 15px;
  padding-right: 15px
}

.breadcrumbs span {
  display: inline-block;
  flex-shrink: 0;
}

.navigationButtons {
  height: 37px;
}

.radioDisplay,
.radioDisplay label {
  height: 37px;
}

.radioDisplay .ant-radio-button-wrapper {
  line-height: 37px;
}

/* File Grid */
.fileGridItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: .2s ease-in-out;
}

.fileGridItem:hover {
  box-shadow: 1px solid black;
  background-color: #ececec;
  border-radius: 5px;
}

.fileGridItem .filename,
.fileGridItem .filetype {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fileGridItem:hover .filename {
  text-decoration: underline;
}

.fileGridItem .filetype {
  font-size: 75%;
  font-weight: 400;
  color: #6b6b6b
}

.fileGridItem.checkedFile {
  border: 1px solid #9b9b9b;
  background-color: #ececec;
}


.context-menu-container {
  position: absolute;
  background: white;
  width: 145px;
  z-index: 1000;
  border: 1px solid rgba(221, 224, 228, 0.5);
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.context-menu-container .context-menu {
  padding: 15px;
  cursor: pointer;
  transition: background 250ms ease-in;
}

.context-menu-container .context-menu:hover {
  background: #eeeff1;
}

.detailModal.ant-modal-wrap {
  pointer-events: none;
}

.detailModal .ant-modal {
  pointer-events: all;
}

.fileSectionActions {
  flex-wrap: wrap;
  place-items: center;
}

/* Fullscreen modal */
.previewModal {
  top: 1.5%;
  left: 1.5%;
}

.previewModal .ant-modal,
.previewModal .ant-modal-content {
  height: 97vh;
  width: 97vw;
  margin: 0;
  top: 0;
}

.previewModal .ant-modal-body {
  height: calc(100vh - 110px);
  padding: 17px 24px 0px 24px;
}

/* React PDF */
.react-pdf__Document {
  background: #5a5a5a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#loghiRti img {
  max-width: 70%;
  height: auto;
  width: 17rem;
  margin: 17px 10px;
}

.appsRow {
  margin: 1.5rem 0 3rem 0
}

.appsRow .linkContainer {
  font-size: 2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.appsRow svg {
  color: black;
  fill: black;
}

.appsRow img {
  height: 1.25em;
}

.menuCardContainer {
  /* width: 80%; */
  margin-left: auto !important;
  margin-right: auto !important;
  background: transparent;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuCardContainer .ant-card-bordered {
  border: 1px solid #a5a5a5;
}

.menuCardContainer .ant-card-head {
  border-bottom: 1px solid #a5a5a5;
}

.menuCardContainer .ant-card-head-title {
  font-size: 2em;
}

.titleSectionApplicazioni {
  font-size: 1.3em;
  margin-left: 1rem;
}

.pageLogo {
  color: rgb(255, 255, 255);
  background: white;
  padding: 7px;
}

.pageLogo img {
  /* height: 35px; */
  height: 55px;
}

.pageLogo h1 {
  line-height: 0.475;
}

.disabledLinks {
  pointer-events: none;
  cursor: inherit;
  color: #7a7a7a;
}


.directoryTreeComponent .ant-tree-switcher {
  display: none;
}

.ant-input-number-handler-wrap {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}